<template>
  <div v-if="columns && columns.length >0">
    <a-table :columns="columns" :data-source="dataList" :bordered="false" :pagination="false" :rowKey="(record,index)=>{return index}">
      <template
        v-for="col in columns"
        :slot="col.dataIndex"
        slot-scope="value, data, index"
      >
        <div :key="col.dataIndex">
<!--          {{value}}-->
          <a-select
            v-if="col.type == 'select'"
            label-in-value
            :value="{ key: value }"
            :allowClear="col.isClear"
            @change="e => handleSelectChange(e, col.dataIndex, index)"
          >
<!--            :default-value="{ key: value }"-->

<!--            :style="{-->
<!--              width: col.width-->
<!--            }"-->
            <a-select-option v-for="option in col.options" :key="option.value + ''" :value="option.value">{{option.label}}</a-select-option>
          </a-select>
          <a-textarea
            v-if="col.type == 'text'"
            :value="value"
            @change="e => handleChange(e.target.value, col.dataIndex, index)"
          />
          <a-input
            v-if="col.type == 'input'"
            :value="value"
            @change="e => handleChange(e.target.value, col.dataIndex, index)"
          />
        </div>
      </template>
      <template slot="operation" slot-scope="value, data, index">
        <div class="editable-row-operations">
          <a-icon class="dynamic-delete-button" type="up-circle" @click="up(index)"/>
          <a-icon class="dynamic-delete-button" type="down-circle" @click="down(index)"/>
          <a-icon class="dynamic-delete-button" type="minus-circle-o" @click="remove(index)"/>
        </div>
      </template>
    </a-table>
    <a-button type="dashed" style="width: 100%; margin-top: 10px" @click="add">
      <a-icon type="plus" />添加
    </a-button>
  </div>
</template>
<script>

export default {
  props: {
    columns: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      dataList: []
    };
  },
  methods: {

    // 初始化
    init(dataList) {
      this.dataList = []
      if (dataList && dataList.length > 0) {
        let sort = 0;
        dataList.map(item => {
          item['sort'] = sort ++
          this.dataList.push(item)
        })
      }
    },
    handleSelectChange(value = '', column, index) {
      this.dataList[index][column] = value ? value.key : ''
      this.dataList[index]['sort'] = index
    },
    // 触发填写
    handleChange(value, column, index) {
      this.dataList[index][column] = value
      this.dataList[index]['sort'] = index
    },

    // 获取数据
    getDataList() {
      return this.dataList
    },

    // 添加行
    add() {
      console.log('this.dataList', this.dataList)
      this.dataList.push({
        // fieldType: 'TEXT',
        // valid: 1
        fieldId: "-1",
        defaultValue: "",
        dictKey: "",
        fieldDetails: "",
        fieldFlag: "",
        fieldName: "",
        fieldType: "TEXT",
        valid: 1,
        sort: 3
      })
    },

    // 删除行
    remove(index) {
      this.dataList.splice(index, 1)
    },

    // 上移
    up(index) {
      if (index != 0) {
        const thisItem = this.dataList[index]
        const targetItem = this.dataList[index - 1]
        thisItem['sort'] = index - 1
        targetItem['sort'] = index
        // this.dataList.splice(index - 1, 0, thisItem, targetItem)
        // this.dataList.splice(index + 1, 2)

        this.dataList.splice((index - 1), 0, this.dataList.splice(index, 1)[0])
      }
    },

    // 下移
    down(index) {
      if (index != this.dataList.length - 1) {
        const thisItem = this.dataList[index]
        const targetItem = this.dataList[index + 1]
        thisItem['sort'] = index + 1
        targetItem['sort'] = index
        this.dataList.splice(index, 0, targetItem, thisItem)
        this.dataList.splice(index + 2, 2)
      }
    },
    // 设置默认值（供外部使用）
    setDefaultList(list) {
      this.dataList = JSON.parse(JSON.stringify(list))
    },
    // 重置
    reset() {
      this.dataList = []
    }
  },
};
</script>
<style scoped lang="less">
.editable-row-operations a {
  margin-right: 8px;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
  margin: 0 5px;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
